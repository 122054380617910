import React from 'react'

const Contents = props => (
  <section className="columns" style={{ margin: '0px' }}>
    <section className="column is-half is-offset-one-quarter">
      <div className="container content" style={{ 'max-width': '600px' }}>
        {props.children}
      </div>
    </section>
  </section>
)

export default Contents
